import { Image, InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/WorkplaceReferrals";
import { useWorkerToWorkplaceAffiliateExperiment } from "@src/appV2/Accounts/WorkplaceReferrals/hooks/useWorkerToWorkplaceAffiliateExperiment";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { isSameDay, parseISO } from "date-fns";

import { useMyShiftsMaxEarnings } from "../../MyShiftUnavailable";
import { formatRate } from "../utils";
import { ShiftCardLoadingPlaceholder } from "./ShiftCardLoadingPlaceholder";

export function EmptyMyShiftsPlaceholder() {
  const { isLoading: isLoadingMaxEarnings, data: maxEarnings } = useMyShiftsMaxEarnings();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  if (isLoadingMaxEarnings) {
    return <ShiftCardLoadingPlaceholder />;
  }

  const { amount = 0, date } = maxEarnings ?? {};

  return (
    <Stack alignItems="center" spacing={2}>
      <Image alt="Clipboard Health logo" src={cbhBunnyLogo} width="160px" />
      {amount === 0 ? (
        <Stack>
          <Text variant="body2" textAlign="center">
            There are no available shifts nearby for you to book.
          </Text>
          <Text variant="body2" textAlign="center">
            Expand your distance preferences in My Account or check back later when more shifts
            become available.
          </Text>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Stack>
            <Text variant="body2" textAlign="center">
              You have not booked any upcoming shifts!
            </Text>
            <Text variant="body2" textAlign="center">
              Tap Find Shifts on the main menu to look for shifts you can book.
            </Text>
            {isWorkerToWorkplaceAffiliateProgramEnabled ? (
              <Text variant="body2" textAlign="center">
                Don&apos;t see any shifts near you? Earn up to $1000 with our{" "}
                <InternalLink
                  aria-label="Affiliate Program"
                  to={`${WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH}?source=myShifts`}
                >
                  Facility Affiliate Program
                </InternalLink>
                !
              </Text>
            ) : (
              <Text variant="body2" textAlign="center">
                Earn <b>{formatRate(amount)}</b> per shift
                {isDefined(date) && isSameDay(new Date(), parseISO(date)) ? <b> today</b> : null}!
              </Text>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
